<template>
  <div>
     <CRow>
 <CCol col="12" xl="12">
   <CCard>
     <CCardHeader class="">
     <CRow>
     <CCol col="6" xl="6">
        <h6 class="w-100">Domov  > Nastavenia >  Slovníky</h6>
                 <h1 class="w-100" >Položky slovníka</h1>
     </CCol>
     <CCol col="6" xl="6">
          <div class="text-right system-options w-100 d-block" style="">
            <router-link :to="'/add-taxonomy-item/' + id "><a-button type="primary" >Nová položka slovníka</a-button></router-link>
            <router-link :to="'/taxonomy/' + id "><a-button type="secondary" class="mr-2">Nastavenia slovníka</a-button></router-link>
            <router-link :to="'/tree-taxonomy-items/' + id"><a-button type="secondary" class="mr-2">Preusporiadať</a-button></router-link>
         </div>
     </CCol>
     </CRow>
     </CCardHeader>
     <CCardBody>
                        <div class="table-responsive">

                          <a-table
      :columns="columns"
      :dataSource="data"
      :rowKey="record => record.id"
      :pagination="pagination"
      :rowSelection="rowSelection"
      :loading="loading"
    >
    <template slot="action" slot-scope="inputid">

    <router-link :to="'/edit-taxonomy-item/' + inputid + '/' + id"><a-button class="mr-2">Upraviť</a-button></router-link>
                                                         <a-popconfirm placement="topRight"
                                                                       @confirm="confirm(inputid)"
                                                                       @cancel="cancel"
                                                                       okText="Áno"
                                                                       cancelText="Nie">
                                                             <template slot="title">
                                                                 <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                                                 Položka bude trvalo odstránená z vašej stránky.
                                                             </template>
                                                             <a-button type="secondary"  icon="fas fa-trash"    textColor="default" title="Zmazať">Zmazať</a-button>
                                                         </a-popconfirm>

    </template>
    </a-table>
    <div v-if="rowSelection.selectedRowKeys.length > 0" class="action-panel">
      <a-select
        style="width:150px;"
        v-model="action"
        placeholder="Vyberte si akciu"
        :options="actions"
      />
      <a-select  v-if="action == 'multiedit'" mode="multiple" style="width:150px;"  class="mr-3" size="small" placeholder="Vyberte si polia" v-model="actionFields"  :options="allfields"></a-select>

      <template v-if="action !== 'multiedit'">
        <a-popconfirm
          placement="topRight"
          @confirm="doAction"
          okText="Áno"
          cancelText="Nie"
        >
          <a-button type="warning">Vykonať</a-button>
        </a-popconfirm>
      </template>
      <template v-else>
        <a-button type="secondary" @click="multiedit">Do úprav</a-button>
      </template>
    </div>
  </div>


                      </CCardBody>
                            </CCard>
                            </CCol>
                            </CRow>

                    </div>

</template>
<script>
    import {dataService} from "../../_services";
    import {Popconfirm} from "ant-design-vue"

    import draggable from "vuedraggable";

    export default {
        name: "taxonomyitems",
        props: ['id'],
        components: {
            "a-popconfirm": Popconfirm, draggable
        },
        data() {
            return {
              data: [],
              allfields:[],
              actionFields:[],
      action: undefined,
      pagination: {
                    hideOnSinglePage: true,
                    pageSize: 100, // default number of pages per page
                    showSizeChanger: true, // display can change the number of pages per page
                    pageSizeOptions: ['10', '20', '30', '40', '100'], // number of pages per option
                    showTotal: total => `Položiek: ${total}`, // show total
                    showSizeChange: (current, pageSize) => {  this.pageSize = pageSize; }, // update display when changing the number of pages per page
                },
      actions: [
        { value: "delete", label: "Zmazať" },
        { value: "export", label: "Exportovať" },
        { value: "multiedit", label: "Hromadná úprava" }
      ],
      selectedRowKeys: [],
      columns: [
      {
          title: 'Položka',
          dataIndex: 'title',
          key: 'title'
        },
        {
          dataIndex: 'id',
          key: 'id',
          align: 'right',
          scopedSlots: {customRender: 'action'},
        }, ],
                dragging: false,
                colorsto: JSON.parse(localStorage.colors),
                loading: false,
            };
        },
        computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys;
        }
      };
    }
  },
        mounted() {
            this.fetch();
        },
        methods: {
          doAction() {
      // Implementujte logiku pre vykonanie akcie na vybraných položkách.
      // Napríklad:
      dataService.axiosPost('action/taxonomy/' + this.action, { items: this.selectedRowKeys })
        .then(() => {
          this.$message.success('Akcia vykonaná');
          // Obnovte dáta alebo resetujte výber:
          this.selectedRowKeys = [];
          this.fetch();
        });
    },
    multiedit()
            {
                this.$router.push({ name: 'multiedittaxonomy', query: { id:this.id, nids: this.rowSelection.selectedRowKeys.join(','), acfields: this.actionFields.join(',') } });
            },
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            cancel() {
            },
            updateOrder()
            {
                dataService.axiosPost("reorder/taxonomy", this.data).then(results => {
                    if(results.data)
                    {
                        this.$message.success('Položky boli zoradené');
                    }

                })
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch(this.$options.name,this.id).then(results => {
                    this.data = results;
                    this.loading = false;
                });




                dataService.axiosFetch("taxonomy/" + this.id).then(results => {


                            if(results.fields)
                          {

                            results.fields.forEach(obj => {

                            if(obj.type == "1" || obj.type == "5" || obj.type == "6" || obj.type == "7" || obj.type == "4" || obj.type == "12")
                            {
                                this.allfields.push({"value":obj.slug,"label":obj.title});
                            }
                            })
                          }

                      });






            }

        },
    };
</script>

<style>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }

    .handle i
    {
        color: #888888;
    }
    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination5-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination5-item.ant-pagination5-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination5-item-link.ant-pagination5-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
